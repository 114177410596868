import { useRecoilState } from 'recoil';

import { LeadFormType } from '@/app/_components/modals/enquire-forms/enquiry-forms/enquiry-forms';
import {
  drawerState,
  floatingCTAShowState,
  formConfigState,
  formItemState,
  NewCarItem,
  pageFormItemState,
  SpecialItem,
  UsedCarItem,
} from '@/states/floating-cta.state';

type FormNames = {
  [key in LeadFormType]: string;
};

export const FORM_NAMES = {
  'new-cars': 'NewCars',
  'used-cars': 'UsedCars',
  newsletter: 'Newsletter',
  parts: 'Parts',
  service: 'Service',
  general: 'TestDrive',
  'test-drive': 'TestDrive',
  'trade-in': 'TradeIn',
} as FormNames;

export const useFloatingCTA = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(drawerState);
  const [isCTAShown, setIsCTAShown] = useRecoilState(floatingCTAShowState);
  const [formConfig, setFormConfig] = useRecoilState(formConfigState);
  const [formItem, setFormItem] = useRecoilState(formItemState);
  const [pageFormItem, setPageFormItem] = useRecoilState(pageFormItemState);

  const openLeadForm = (
    leadType: LeadFormType,
    formName?: string,
    item?: NewCarItem | UsedCarItem | SpecialItem,
  ) => {
    setFormConfig({
      formName: formName
        ? formName
        : FORM_NAMES[item?.type === 'special' ? item.leadType : leadType],
      leadType: item?.type === 'special' ? item.leadType : leadType,
    });
    if (item) {
      setFormItem(item);
    }
    setIsDrawerOpen(true);
  };

  const closeLeadForm = () => {
    setIsDrawerOpen(false);
  };

  const clearLeadForm = () => {
    setFormItem(undefined);
  };

  return {
    isDrawerOpen,
    leadFormName: formConfig.formName,
    openLeadForm,
    closeLeadForm,
    clearLeadForm,
    leadType: formConfig.leadType,
    formConfig,
    formItem: formItem || pageFormItem,
    setFormItem: setPageFormItem,
    isCTAShown,
    setIsCTAShown,
  };
};
